import { parseQueryString, getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import expose from 'public/src/services/expose/index.js'

const isBrowser = typeof window !== 'undefined'
export const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
export const isAndroid = UA && UA.indexOf('android') > 0
// fix: 兼容ios13后ipad使用桌面端wkWebview，导致ua中不存在ipad标识，window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1
export const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA)) || (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
/**
 * @param {object} abtParams 
 * @param {string} key 
 * @param {boolean} isRomwe 
 * @param {boolean} isParse 
 * @returns 
 */
export function getAbtParams(abtParams = {}, key = '', isRomwe = false, isParse = true) {
  if (Object.keys(abtParams).length > 0) {
    const prefix = isRomwe ? 'RM' : 'SM'
    const posKey = prefix + (isRomwe ? key.replace(/(Rw)(\w*)(m)/, '$2') : key.replace(/(Sh)(\w*)(Pwa)/, '$2'))
    let abtTypeId = abtParams[posKey] && abtParams[posKey].p
    if (!abtTypeId) abtTypeId = abtParams[`${prefix}ActDefault`] && abtParams[`${prefix}ActDefault`].p
    if (abtTypeId && typeof abtTypeId === 'string') {
      if (isParse) {
        return parseQueryString(abtTypeId)
      } else {
        return abtTypeId
      }
    }
  }
  return ''
}

export const handleColor = (str) => {
  if(!str) return ''
  if(str.length === 9) {
    return str[0].concat(str.slice(3, 9), str.slice(1, 3))
  }
  return str
}

const getAlphafloat = (a, alpha) => {
  if (typeof a !== 'undefined') { return a / 255 }
  if ((typeof alpha != 'number') || alpha < 0 || alpha > 1){
    return 1
  }
  return alpha
}

/**
 *  const c1 = "#f80"
    const c2 = "#f808"
    const c3 = "#0088ff"
    const c4 = "#0088ff88"
    const c5 = "#98736"
    console.log(hexToRGBA(c1))   //  rgba(255, 136, 0, 1)
    console.log(hexToRGBA(c2))   //  rgba(255, 136, 0, 0.53125)
    console.log(hexToRGBA(c3))   //  rgba(0, 136, 255, 1)
    console.log(hexToRGBA(c4))   //  rgba(0, 136, 255, 0.53125)
    console.log(hexToRGBA(c5))   //  Uncaught Error: Invalid HEX
    console.log(hexToRGBA(c1, 0.5))   //  rgba(255, 136, 0, 0.5)
    console.log(hexToRGBA(c3, 0.5))   //  rgba(0, 136, 255, 0.5)
 * @param {String} hex 十六进制字符串
 * @param {Number} alpha 0 ~ 1
 * @returns 
 */
export const hexToRGBA = (hex, alpha) => {
  if (!/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) { throw new Error('Invalid HEX') }
  const chunkSize = Math.floor((hex.length - 1) / 3)
  const hexArr =  hex.slice(1)?.match(new RegExp(`.{${chunkSize}}`, 'g'))
  const [r, g, b, a] = hexArr.map((hexStr) => {
    return parseInt(hexStr.repeat(2 / hexStr.length), 16)
  })
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
}

export const timeZone = () => { // 时区
  const date = new Date()
  const timeZone = date.getTimezoneOffset() / 60
  return `GMT${timeZone > 0 ? '-' : '+'}${Math.abs(timeZone)}`
}

export async function getServerTime() {
  try {
    const data = await schttp({ url: '/api/ccc/serverTime/get' })
    return data?.time || null
  } catch (error) {
    return null
  }
}

const  generateRandomNumber = function(){
  const currentTime = new Date().getTime() // 获取当前时间的毫秒数
  const random = Math.floor(Math.random() * 1000) // 生成0到999之间的随机数
  return `${currentTime}${random}` // 将当前时间与随机数相加作为最终结果
}
// cccx 多组件通信
// 如果一定需要用到
// eventName：组件名称-事件名称
// 支持emit同一eventName，
export const cccxEventBus = {
  event: {},
  once(eventName, payload) {
    if (typeof payload !== 'function') return
    this.event[eventName] = [{ key: generateRandomNumber(), fn: payload }]
  },
  off(eventName, eventNameId) {
    if(eventNameId) {
      const index = this.event[eventName].findIndex(v => v.key === eventNameId)
      index !== -1 && this.event[eventName].splice(index, 1)
    } else {
      delete this.event[eventName]
    }
    
  },
  on(eventName, payload, eventNameId) {
    if (typeof payload !== 'function') return
    if (this.event[eventName]) {
      if(eventNameId) {
        this.event[eventName].push({ key: eventNameId, fn: payload })
      } else {
        this.event[eventName].push({ key: generateRandomNumber(), fn: payload })
      }
    } else {
      if(eventNameId) {
        this.event[eventName] = [{ key: eventNameId, fn: payload }]
      } else {
        this.event[eventName] = [{ key: generateRandomNumber(), fn: payload }]
      }
      
    }
  },
  emit(eventName, ...arg) {
    this.event?.[eventName]?.forEach(({ fn: payload }) => payload(...arg))
  },
}



// 处理多语言奖池
export function handleMultiLanguagePrizePool(prizeList, lang){
  console.log('prizeList: ', prizeList)
  if(!prizeList || !prizeList.length) return []
  const result =  prizeList.map((item) => {
    const multilingualismData =
    item?.prize.multilingualismDataList.find(
      (prizeItem) => prizeItem.lang === lang
    ) || {}
    return {
      ...item,
      prize: {
        ...item.prize,
        ...multilingualismData,
      },
    }
  })
  return result || []
}

export function delay(time = 500) {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

/**
 * 深度冻结对象
 * @param {Object} obj 需要冻结的对象
 * @returns
 */
export function deepFreeze(obj) {

  for(const key in obj) {

    if(Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key]

      // 如果属性是一个对象，递归调用deepFreeze
      if (typeof value === 'object' && value !== null) {
        deepFreeze(value)
      }
    }
  }

  // 返回冻结后的对象
  return Object.freeze(obj)
}

export const dayOfWeekInBeijing = (date) => {
  // 获取当前日期的世界标准时间
  const now = new Date(date)
  const utcTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
    now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())

  // 计算时差，北京时区是UTC+8
  const ONE_HOUR = 60 * 60 * 1000
  const beijingTimestamp = utcTimestamp + ONE_HOUR * 8

  // 将时间戳转换为日期对象，再获取星期几
  const beijingDate = new Date(beijingTimestamp)
  return beijingDate.getDay()
}

/**
 * 判断对象是否冻结
 * @param {Object} obj
 * @returns
 */
export function handleObjIsFrozen(obj) {
  // 若原始的obj已被冻结 深拷贝一份
  return Object.isFrozen(obj) ? JSON.parse(JSON.stringify(obj)) : obj
}

/**
 * 单行显示文本字体大小容器内自适应
 * @param {Element} containerEl 容器元素【必填】
 * @param {Element} textEl 文本元素【必填】
 * @param {Number} minFontSize 最小fontSize
 */
export const adjustFontSizeSingle = (function() {
  let fontIsReady = false
  let adjustConfigList = []
  if (typeof window === 'undefined') {
    fontIsReady = true
  } else {
    document.fonts.ready.then(function() {
      // 字体加载完成
      fontIsReady = true
      adjustConfigList.forEach(config => {
        adjustFontSizeSingle(config)
      })
    })
  }

  return function (config = {}) {
    if (!fontIsReady) {
      adjustConfigList.push(config)
      return
    }
    config = {
      minFontSize: config?.minFontSize || 8,
      ...config,
    }

    const { containerEl, textEl, minFontSize, calcText } = config

    if (!containerEl || !textEl) return

    const containerWidth = containerEl.offsetWidth - (32 * (window.innerWidth / 750)) // 留32vw的padding
    const { fontFamily, fontSize = '', fontWeight } = getComputedStyle(textEl)

    const text = textEl.innerText || calcText
    const offCtx = document.createElement('canvas').getContext('2d')
    offCtx.font = `${fontWeight} ${fontSize} ${fontFamily}`
    const { width: measuredWidth } = offCtx.measureText(text)

    // --------------主按钮字体计算--------------
    if (measuredWidth < containerWidth) return

    // 按比例缩小字号到刚好占满容器, 左右需要留padding
    const newFontSize = `${Math.max(
      parseInt(Number(fontSize.replace('px', '')) * (containerWidth / measuredWidth)),
      minFontSize
    )}px`

    // 设置文本不折行以计算文本总长度
    textEl.style.whiteSpace = 'nowrap'
    textEl.style.fontSize = newFontSize
  }
})()

/**
 * 测量文本宽度
 * @param {String} text 需要测试的文本
 * @param {String} font 字体样式
 * @returns 
 */
export const getTextWidth = (text, font = 'normal 12pt arial') => {
  if (!text) return 12
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d') 
  context.font = font
  const metrics = context.measureText(text)

  return Math.ceil(metrics.width)
}

/**
 * 容器内文本大小自适应
 * @param {Element} containerEl 容器元素【必填】
 * @param {Element} textEl 文本元素【必填】
 * @param {Number} minFontSize 最小fontSize 
 * @param {Number} maxFontSize 最大fontSize 
 * @param {Boolean} afterOverflowResponsive 是否溢出才响应, 默认 true
 * @returns 
 */
export function autoResponsiveFontSize(config = {}) {
  config = {
    minFontSize: 8,
    maxFontSize: 80,
    afterOverflowResponsive: true,
    ...config,
  }

  const { containerEl, textEl, minFontSize, maxFontSize,  afterOverflowResponsive } = config

  if (!containerEl || !textEl) return

  const text = textEl.innerText 
  const NUM = text.length
  const W = containerEl.offsetWidth
  const H = containerEl.offsetHeight
  const rawTextStyle = document.defaultView.getComputedStyle(textEl, null)
  const rawFontSize = rawTextStyle.fontSize?.replace('px', '')

  // 边界最大最小值， 三个不等式 H - fs <= row * fs <= H    W - fs <= column * fs <= W     row * column >= NUM(当成等于处理)
  let fontSizeMin = (Math.sqrt((W + H) * (W + H) + 4 * W * H * NUM - 4 * W * H) - W - H) / (2 * NUM - 2) || minFontSize

  let signState = true
  let fontSize = fontSizeMin
  while (signState) {
    if (Math.ceil(NUM / Math.floor(W / fontSize)) * fontSize > H || fontSize > maxFontSize) {
      // 中止
      signState = false
      break
    } else {
      // 继续
      signState = true
    }
    fontSize = fontSize + 1
  }
  fontSize = fontSize - 1

  // 不溢出情况不重置fontSize
  if (afterOverflowResponsive && rawFontSize && (fontSize > rawFontSize || getTextWidth(text, rawTextStyle.font) < W)) {
    return
  }

  if (fontSize >= maxFontSize) {
    fontSize = maxFontSize
  }
  if (fontSize <= minFontSize) {
    fontSize = minFontSize
  }

  textEl.style.fontSize = `${fontSize}px`
}

export function handleAutoPlay(swiperEl, exposeOptions = {
  mask: ['0', '0', '0', '0'],
}) {
  if(!swiperEl) return
  const obInstance = new expose({ observeHide: true, exposeRatio: 0.99, mask: exposeOptions.mask })
  obInstance.observe(
    {
      elements: swiperEl,
      once: false,
    },
    ({ exposeDoms }) => {
      if (!swiperEl.swiper) return
      if (exposeDoms.length) {
        swiperEl.swiper?.autoplay?.resume()
      } else {
        swiperEl.swiper?.autoplay?.pause()
      }
    },
  )
  return obInstance
}

/**
 * 添加列表公共参数
 * @param {Object} options 
 * @param {Object} options.sceneData 场景数据
 * @returns 
 */
export function getListCommonParams({ sceneData, propData, index }) {
  if (typeof window === 'undefined') return {}
  const componentId = getQueryString({ key: 'componentId' })
  const componentAdp = getQueryString({ key: 'componentAdp' })

  const result = {
    scene: sceneData?.pageFrom,
    componentId: propData?.componentId,
    styleType: propData?.styleType,
    channelName: sceneData.tabName,
    blockKey: propData.blockKey ?? '-'
  }

  // 专题页面组件，根据 componentId 配置置顶 adp
  if (sceneData?.pageFrom === 'campaigns' && +componentId === propData?.componentId && componentAdp && index === 0) {
    result.adp = componentAdp
  }

  return result
}

/**
 * 获取justWatch样式配置
 * @param {String} componentId 
 * @returns 
 */
export function getJustWatchConfig(originalUrl, curComponentId) {
  const justWatchStyleConfig = {
    isAdpComp: false,
    adp: 0,
  }

  if (!originalUrl) {
    return justWatchStyleConfig
  }

  try {
    const searchParams = new URLSearchParams(originalUrl.split('?')[1])
    const componentId = searchParams.get('componentId')
    const componentAdp = searchParams.get('componentAdp') // null

    // 判断是否命中当前组件
    justWatchStyleConfig.isAdpComp = componentId && componentId == curComponentId 
    // 获取adp
    const adpList = componentAdp || '0'
    justWatchStyleConfig.adp = adpList.split(',')[0]

  }catch(e) {
    console.log(e)
  }
  return justWatchStyleConfig
}
